import SectionHeader from '../components/sections/header';
import SectionBreadcrumbs from '../components/sections/breadcrumbs';
import SectionProductSticky from '../components/sections/product-sticky';
import SectionProductSlider from '../components/sections/product-slider';
import SectionFooter from '../components/sections/footer';

import React from 'react';
import { graphql } from 'gatsby';

import { IPage } from '../models/page.model';
import { IProduct, IProductOption, IProductOptionValue } from '../models/product.model';
import { IProductVariant } from '../models/product-variant.model';
import { ProductContextProvider } from '../contexts/product-content';
import getPageConfigFromSections from '../utils/get-page-config-from-sections';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';

interface IPostProps {
    readonly data: {
        page: IPage;
        product: IProduct;
    };
}

const Product: React.FC<IPostProps> = ({ data }) => {
    const { page, product } = data;

    const sections = page.sections.map((section) => {
        return section.type === 'product-simple' || section.type === 'product-sticky'
            ? { ...section, settings: { ...section.settings, isMain: true } }
            : section;
    });
    const { mainSectionIndex } = getPageConfigFromSections(sections);

    const sectionsWithData = sections
        .map((section) => {
            if (section.type === 'product-simple' || section.type === 'product-sticky') {
                return {
                    ...section,
                    extendedItems: {
                        product,
                    },
                };
            }

            if (section.type === 'product-slider') {
                if (product.relatedProducts && product.relatedProducts.length === 0) return null;

                return {
                    ...section,
                    items: {
                        products: product.relatedProducts,
                    },
                };
            }
            return section;
        })
        .filter(nonNullable);

    const preselectedOptions = getOptions(
        product.variants && product.variants?.length > 0 ? product.variants : undefined,
        product.options
    );

    return (
        <MainLayout>
            <ProductContextProvider
                options={preselectedOptions}
                variant={product.variants ? product.variants[0] : undefined}
            >
                {sectionsWithData.map((section, index) => {
                    return (
                        <SectionFactory
                            key={`section-${section.type}-${section.sectionId}`}
                            SectionComponent={sectionComponents[section.type]}
                            section={section}
                            TitleTag={mainSectionIndex === index ? 'h1' : 'h2'}
                        />
                    );
                })}
            </ProductContextProvider>
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $productId: Int!, $locale: String!) {
        page(pageId: { eq: $pageId }) {
            sections {
                ...sectionFields
            }
        }
        product(productId: { eq: $productId }, locale: { eq: $locale }) {
            ...productFields
            relatedProducts {
                ...productFields
            }
            combinedProducts {
                ...productFields
            }
            alternativeProducts {
                ...productFields
            }
        }
    }
`;

function nonNullable<T>(value: T): value is NonNullable<T> {
    return Boolean(value);
}

const getOptions = (
    variants: IProductVariant[] | undefined,
    options: IProductOption[] | undefined
) => {
    if (options && options?.length === 1) {
        let found: IProductOptionValue | undefined = undefined;

        options[0].values.forEach((value) => {
            if (found) return;
            variants?.forEach((variant) => {
                if (found) return;

                if (variant.quantity > 0 && variant.variantId === value.variants[0] && !found) {
                    found = value;
                }
            });
        });
        return [found];
    } else {
        if (variants && variants[0] && options) {
            return options?.map((option) => {
                let found = undefined;
                option.values.forEach((value) => {
                    if (value.variants.includes(variants[0].variantId)) found = value;
                });
                return found;
            });
        }
        return options?.map(() => undefined);
    }
};

export default Product;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'breadcrumbs': SectionBreadcrumbs,
    'product-sticky': SectionProductSticky,
    'product-slider': SectionProductSlider,
    'footer': SectionFooter,
};