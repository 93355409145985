import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

import { IQueryAllResult } from '../models/query-all-result.model';
import { ISetting } from '../models/setting.model';
import { getNodes } from '../utils/get-nodes';

interface IUseSettingsQueryResult {
    allSetting: IQueryAllResult<ISetting>;
}

export const useSettings = (settingNames?: string[]) => {
    const { allSetting } = useStaticQuery<IUseSettingsQueryResult>(query);
    return useMemo(() => {
        const settings = getNodes(allSetting);
        if (!settingNames) return settings;
        const wantedSettings = settings.filter((setting) => settingNames.includes(setting.name));
        return settingNames.map((settingName) => {
            const setting = wantedSettings.find(
                (wantedSetting) => wantedSetting.name === settingName
            );
            return setting || null;
        });
    }, [settingNames, allSetting]);
};

const query = graphql`
    query {
        allSetting {
            edges {
                node {
                    name
                    value
                    format
                }
            }
        }
    }
`;
